// Imports
import axios from "axios";
import { createRealmApp } from "./RealmApp";
require("./interceptor");
const dataSource = process.env.REACT_APP_MONGO_DATASOURCE;
const database = process.env.REACT_APP_MONGO_DATABASE;

export function pricingRulesEngine(foal, sire) {
  let price = 0;
  let today = new Date();
  let year = today.getFullYear();
  console.log(year);
  let sireAge = year - Number(sire);
  let foalAge = year - Number(foal);
  console.log(sireAge, foalAge);

  if (
    today >= new Date("December 31, " + (year - 1).toString() + " 11:59:59") &&
    today <= new Date("March 17, " + year.toString() + " 11:59:59")
  ) {
    if (sireAge > 0) {
      if (foalAge > 3) price = 2000;
      else if (foalAge == 3) price = 1000;
      else if (foalAge == 2) price = 500;
      else if (foalAge == 1) price = 250;
      else if (foalAge >= 0) price = 250;
      else price = 250;
    }
    if (sireAge <= 0) {
      price = 250;
    }
  }

  /*
    After March 15th
  */
  if (today >= new Date("March 17, " + year.toString() + " 11:59:59")) {
    //2021 or 2022
    if (sireAge > 0) {
      if (foalAge > 3) {
        price = 2000;
      } else if (foalAge == 3) {
        price = 1000;
      } else if (foalAge == 2) {
        price = 500;
      } else if (foalAge == 1) {
        price = 400;
      } else if (foalAge >= 0) {
        price = 250;
      } else {
        price = 250;
      }
    }

    if (sireAge <= 0) {
      if (foalAge > 0) price = 400;
      else price = 250;
    }
  }

  //over ride to base fee if engine fails

  if (price == 0) price = 250;

  if (
    today >= new Date("June 1, " + year.toString() + " 00:00:01") &&
    today <= new Date("June 4, " + year.toString() + " 11:59:59") &&
    (price == 1000 || price == 2000)
  ) {
    price = 500;
  }

    //over ride for 25
    if(sire == 2025)
      price = 250

  return price;
}
const insert = (collection, data) => {
  return axios
    .post(`/action/insertOne`, {
      dataSource,
      database,
      collection: collection,
      document: data,
    })
    .then((response) => {
      return response.data.documents;
    })
    .catch((error) => {
      throw error;
    });
};

const get = (collection, query) => {
  return axios
    .post(`/action/find`, {
      dataSource,
      database,
      collection: collection,
      filter: query,
    })
    .then((response) => {
      return response.data.documents;
    })
    .catch((error) => {
      throw error;
    });
};

export async function generateId() {
  const app = createRealmApp();
  const mongo = app.currentUser.mongoClient("mongodb-atlas");
  const db = mongo.db("legends");
  const last = await db
    .collection("nominators")
    .aggregate([
    {
      $project: {
        membershipId: 1,
        numericId: {
          $toInt: {
            $arrayElemAt: [
              {
                $split: ["$membershipId", "TL-"]
              },
              1
            ]
          }
        }
      }
    },
    {
      $sort: {
        numericId: -1
      }
    },
    {
      $limit: 1
    }
  ]);
  const num = Number(last[0].membershipId.split("TL-")[1]);
  return `TL-${num + 1}`;
}

export const formatMoney = (amount) => {
  if (!amount) return "";
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);
}

export const formatDate = (dateString) => {
  let date = dateString
  if (!dateString) return "";
  if (typeof date == 'string') date = new Date(dateString);

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  }).format(date);
};